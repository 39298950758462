<template>
  <!--  노션 링크 : https://www.notion.so/InfoguidancePage-7733b7e89dd44cee9e0c1193a6f3e794 -->
  <div>
    <div>
      <breadcrumb :breadcrumb_link="introduce_breadcrumb" />
    </div>
    <div class="header">
      <!--      <h1 class="overlay">인재채용</h1>-->
      <div class="headerimage" />
    </div>

    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="operationguide_body_hedaer col-md-12 mt-5 mb-4">
          <h2 class="text-center"><strong>인재채용</strong></h2>
        </div>
      </div>

      <div class="web-nav">
        <div id="btn-radios-1" class="d-flex justify-content-center categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>
      <div class="mobile-nav mx-auto">
        <div id="btn-radios-2" class="categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>
      <div style="min-height: 30rem" class="">
        <div class="recruit_title">
          <span>복리후생</span>
        </div>
        <div style="margin-top: 3rem" />
        <div class="back">
          <img :src="require('@/assets/images/main/img.png')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';

export default {
  name: 'HrWalfare',
  data() {
    return {
      introduce_breadcrumb: {
        toplink: 'HOME',
        prevlink: '인재채용',
        present: '복리후생',
      },
      selectedCategory: '7',
      categories: [
        {value: '1', to: '/p/HrAbout', label: '채용안내'},
        {value: '2', to: '/p/HrNotice', label: '채용공고'},
        {value: '3', to: '/p/HrNoticeResult', label: '채용공고결과'},
        {value: '4', to: '/p/HrDbReg', label: '인재DB등록'},
        {value: '5', to: '/p/HrFaq', label: 'FAQ'},
        {value: '6', to: '/p/HrWant', label: '인재상'},
        {value: '7', to: '/p/HrWalfare', label: '복리후생'},
        {value: '8', to: '/p/Notice', label: '공지사항'},
        {value: '9', to: '/p/HrPolicy', label: '개인정보 처리방침'},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '복리후생'});
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style scoped lang="scss">
.itemContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1rem;
}
.rowSpace {
  height: 1rem;
}
.colSpace {
  width: 0.5rem;
}
.optionText {
  font-size: 0.75rem;
  color: #717171;
}
.categoryRadioGroup {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px;
}
.cateBoxContainer.checked .cateBox {
  background-color: #343a40;
}

.cateBoxContainer.checked .cateBox span {
  color: white;
}
input[type='radio'] {
  display: none;
}

.cateBox {
  background-color: white;
  border: 1px solid #b7b7b7;
  width: 100%;
  height: 3rem;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
  line-height: 3rem;
}

.cateBox span {
  font-size: 0.9rem;
  user-select: none;
  color: #2f2f2f;
}

.recruit_title {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  text-align: center;
}

.recruit_title2 {
  font-size: 1.1rem;
  text-align: center;
}

.back {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
  }
}
.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/main/bg_recruit.png');
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .web-nav {
    display: table;
    width: 100%;
  }
  .mobile-nav {
    display: none;
  }
  .cateBoxContainer {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .web-nav {
    display: none;
  }
  .mobile-nav {
    display: table;
  }
  .cateBoxContainer {
    width: 33%;
  }
}
@media (max-width: 992px) {
  .headerimage {
    display: none;
  }

  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }
  }
}
</style>
